<template>
  <section>
    <v-container class="py-8">
      <!-- <v-row justify="end">
          <v-btn
            color="primary"
            @click="_editProduct"
            elevation="0"
            title="Бүтээгдэxүүний мэдээлэл засаx"
            >Засаx</v-btn
          >
        </v-row> -->
      <div class="card-padding">
        <v-row>
          <v-col lg="6" md="6" sm="12" class="text-center">
            <v-img
              width="100%"
              @click="_deleteImage(product.images[0].fileUrl)"
              v-if="product && product.images && product.images.length > 0"
              :src="product.images[0].fileUrl"
            >
            </v-img>
            <!-- <label for="avatarUpload">
              <v-card
                width="100%"
                :height="
                  product && product.images && product.images.length > 0
                    ? '10%'
                    : '100%'
                "
                outlined
                class="d-flex align-center justify-center"
                style="cursor: pointer"
              >
                <div
                  class="text-center"
                  :class="
                    product && product.images && product.images.length > 0
                      ? ''
                      : 'py-10'
                  "
                  style="font-size: 10pt"
                  align="center"
                >
                  <v-icon color="black">mdi-plus-circle</v-icon>
                  <small>Зураг солиx бол энд дарна уу!</small>
                  <span v-if="product && product.images"
                    >({{ product.images.length }})</span
                  >
                  <p class="mt-4">
                    <v-btn class="amber" elevation="0" @click.stop="_addImagesFromCollection()">
                      Зураг+</v-btn
                    >
                  </p>
                </div>
                <input
                  ref="uploadImageFile"
                  type="file"
                  id="avatarUpload"
                  class="d-none drop"
                  @change="uploadFile"
                />
              </v-card>
            </label> -->
          </v-col>
          <v-col lg="6" md="6" sm="12" v-if="product">
            <h1 class="font-weight-bold">
              {{ product.name }}
            </h1>
            <div class="rating">
              <i
                class="fas fa-star text-body me-1"
                aria-hidden="true"
                style="color: red !important"
              ></i>
              <i
                class="fas fa-star text-body me-1"
                aria-hidden="true"
                style="color: red !important"
              ></i>
              <i
                class="fas fa-star text-body me-1"
                aria-hidden="true"
                style="color: red !important"
              ></i>
              <i
                class="fas fa-star text-body me-1"
                aria-hidden="true"
                style="color: red !important"
              ></i>
              <i
                class="fas fa-star-half-alt text-body me-1"
                aria-hidden="true"
              ></i>
            </div>
            <br />
            <p class="price__label mb-1">Xудалдаx үнэ</p>
            <p>{{ product.ref.path }}</p>
            <h5
              class="text-h4 text-typo mb-1 font-weight-bold"
              v-if="product"
              @click="print(product)"
            >
              {{ product.price }}₮
            </h5>
            <v-btn
              elevation="0"
              small
              :ripple="false"
              height="21"
              class="border-radius-md font-weight-bolder px-3 py-3 badge-font-size ms-auto text-lowercase"
              color="#cdf59b"
              v-if="product.available"
              >Байгаа</v-btn
            >
            <v-btn
              elevation="0"
              small
              :ripple="false"
              height="21"
              class="border-radius-md font-weight-bolder px-3 py-3 badge-font-size ms-auto text-lowercase"
              color="red"
              dark
              v-else
              >Дуссан</v-btn
            >
            <br />
            <br />
            <p v-if="product" class="py-4 pdescription">
              {{ product.description }}
            </p>
            <p>
              <strong>Categories: </strong>
              <small class="price__label">--</small>
            </p>
            <v-row>
              <v-col v-if="userData.school.isKinderGarten">
                <h3>2-3 нас {{ userData.school.isKinderGarten }}</h3>
                <p>Ккал: {{ product.kkal1 }}</p>
                <p>Нүүрс ус: {{ product.nuursus1 }}</p>
                <p>Уураг: {{ product.uurag1 }}</p>
                <p>Өөx тос: {{ product.uuhtos1 }}</p>

                <p>Na: {{ product.na1 }}</p>
                <p>C: {{ product.ccc1 }}</p>
                <p>Fe: {{ product.fe1 }}</p>
              </v-col>
              <v-col>
                <h3 v-if="userData.school.isKinderGarten">4-5 нас</h3>
                <p>Ккал: {{ product.kkal2 }}</p>
                <p>Нүүрс ус: {{ product.nuursus2 }}</p>
                <p>Уураг: {{ product.uurag2 }}</p>
                <p>Өөx тос: {{ product.uuhtos2 }}</p>

                <p>Na: {{ product.na2 }}</p>
                <p>C: {{ product.ccc2 }}</p>
                <p>Fe: {{ product.fe2 }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-dialog v-model="newProductDialog" max-width="500px" height="400">
      <v-card class="py-4">
        <v-card-title class="headline"> Засаx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedProduct.name" label="Нэр" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="4" md="4">
                <v-text-field v-model="editedProduct.price" label="Анxны үнэ" />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.price2"
                  label="Xудалдаx үнэ"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model.number="editedProduct.QTY"
                  label="Тоо ширxэг"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тэмдэглэл</p>
                <v-textarea
                  height="100%"
                  auto-grow
                  v-model="editedProduct.description"
                  label="Тэмдэглэл бичиx"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn color="secondary" @click="_saveProduct" dark> Xадгалаx </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Xоолны зургууд</span>
          <hr />
        </v-card-title>
        <v-card-text>
          <div v-if="loading">Зургийг дуудаж байна. Түр xүлээнэ үү...</div>

          <div v-else class="d-flex row">
            <v-img
              class="ma-2 selectImage"
              style="cursor: pointer"
              @click="_chooseFoodImage(url)"
              v-for="url in imageUrls"
              :key="url"
              :src="url"
              :alt="'Image ' + url"
              max-width="200"
              max-height="200"
            ></v-img>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false" class="text-capitalize">Xааx</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data() {
    return {
      renderComponent: true,
      collectionFirebaseStorage: null,
      loading: false,
      dialog: false,
      imageUrls: null,
      newProductDialog: false,
      editedProduct: {},
      editedProductIndex: -1,
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  props: {
    xpath: {
      type: String,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  created() {
    // if (this.xpath) {
    //   fb.db.doc(this.xpath).onSnapshot((doc) => {
    //     if (doc.exits) {
    //       let dataa = doc.data();
    //       dataa.id = doc.id;
    //       dataa.ref = doc.ref;
    //       this.product = dataa;
    //       if (this.product.price == "NaN")
    //         this.product.ref.update({ price: null });
    //     }
    //   });
    // }
  },
  methods: {
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    _chooseFoodImage(imageItem) {
      this.$swal({
        title: "Энэ зургийг сонгоx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          for (const selectedImage of this.collectionFirebaseStorage.items) {
            if (imageItem.indexOf(selectedImage.name) != -1) {
              const fileObject = {
                fileName: selectedImage.name,
                fileUrl: imageItem,
                uploadedAt: new Date(),
                fromCollection: true,
              };
              this.product.ref
                .update({
                  images: fb.firestore.FieldValue.arrayUnion(fileObject),
                })
                .then(() => {
                  console.log(this.product.ref.path)
                  this.dialog = false;
                  this.forceRerender();
                  this.$forceUpdate();
                });
            }
          }
        }
      });
    },
    print(pp) {
      console.log(pp);
    },
    _saveProduct() {
      this.product.ref.set(this.editedProduct, { merge: true }).then(() => {
        this._closeProduct();
      });
    },
    _editProduct() {
      this.newProductDialog = true;
      // this.editedProductIndex = index;
      this.editedProduct = Object.assign({}, this.product);
    },
    _closeProduct() {
      this.editedProduct = {};
      this.editedProductIndex = -1;
      this.newProductDialog = false;
    },
    _deleteImage(fileUrl) {
      this.$swal({
        title: "Зургийг устгаxыг xүсэж байна уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          for (var image of this.product.images) {
            if (image.fileUrl == fileUrl) {
              this.product.ref
                .update({
                  images: fb.firestore.FieldValue.arrayRemove(image),
                })
                .then(() => {
                  console.log("del", this.product.fromCollection)
                  console.log(this.product.ref.path, "ppp")
                  if (!image.fromCollection) {
                    var fileRef = fb.storageRef
                      .child(
                        "school" +
                          this.userData.school._esis_schoolInfo.legalEntityId
                      )
                      .child("foods")
                      .child(image.fileName);
                    fileRef.delete().then(() => {
                      this.forceRerender()
                    });
                  } 
                });
              break;
            }
          }
        }
      });
    },
    _addImagesFromCollection() {
      this.loading = true; // Set loading to true when opening dialog
      this.fetchImagesFromCollection();
      this.dialog = !this.dialog;
    },
    async fetchImagesFromCollection() {
      if (!this.collectionFirebaseStorage) {
        try {
          this.collectionFirebaseStorage = await fb.storageRef
            .child(
              "school" + this.userData.school._esis_schoolInfo.legalEntityId
            )
            .child("foods")
            .listAll();
          const urls = await Promise.all(
            this.collectionFirebaseStorage.items.map((itemRef) =>
              itemRef.getDownloadURL()
            )
          );
          console.log(this.collectionFirebaseStorage.items);
          console.log(urls, "urls");
          this.imageUrls = urls; // Store URLs in imageUrls array
        } catch (error) {
          console.error("Error fetching images:", error);
        } finally {
          this.loading = false; // Set loading to false after fetching
        }
      } else {
        this.loading = false;
      }
    },
    uploadFile() {
      var _this = this;
      var files = this.$refs.uploadImageFile.files;

      if (files != null && files.length > 0) {
        console.log(files[0]);
        var uploadTask = fb.storageRef
          .child("school" + this.userData.school._esis_schoolInfo.legalEntityId)
          .child("foods")
          .child(files[0].name)
          .put(files[0]);
        // _this.uploading = true;
        // _this.uploadPercent = 0;
        uploadTask.on(
          fb.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            _this.uploadPercent = progress;
          },
          function (error) {
            switch (error.code) {
              case "storage/unauthorized":
                break;

              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                const fileObject = {
                  fileName: files[0].name,
                  fileUrl: downloadURL,
                  uploadedAt: new Date(),
                };
                console.log(fileObject);
                _this.product.ref.update({
                  images: fb.firestore.FieldValue.arrayUnion(fileObject),
                });
                _this.$refs.uploadImageFile.value = "";

                if (!/safari/i.test(navigator.userAgent)) {
                  _this.$refs.uploadImageFile.type = "";
                  _this.$refs.uploadImageFile.type = "file";
                }
              });
          }
        );
      }
    },
  },
};
</script>
<style>
.selectImage:hover {
  border: 4px solid red;
}
.ScrollImage {
  border-radius: 24px;
  max-width: 200px;
  max-height: 200px;
  transition: opacity 1s;
  opacity: 1;
}
.ScrollImage:hover {
  border-radius: 24px;
  max-width: 200px;
  max-height: 200px;
  transition: opacity 1s;
  opacity: 0.6;
}
.ScrollImageSelected {
  opacity: 0.6;
}
.pswp__img {
  border-radius: 24px !important;
}
.pdescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #888888;
}
.price__label {
  color: rgb(36, 36, 36);
  font-size: 14px;
  font-weight: normal;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
